/*eslint-disable*/
import React, { useState } from "react";
import Axios from 'axios';
import ApiConfig from "_config/ApiConfig";
import { Link } from "react-router-dom";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
//import Tooltip from "@material-ui/core/Tooltip";
//import List from "@material-ui/core/List";
//import ListItem from "@material-ui/core/ListItem";
import Card from "components/Card/Card.js";

// @material-ui/icons
import Camera from "@material-ui/icons/Camera";

import ImageUpload from "components/CustomUpload/ImageUpload.js";

//import AccountCircle from "@material-ui/icons/AccountCircle";
import ArtTrack from "@material-ui/icons/ArtTrack";
//import Remove from "@material-ui/icons/Remove";
//import Add from "@material-ui/icons/Add";
//import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
// core components
import Header from "components/Header/Header.js";
//import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import NavPills from "components/NavPills/NavPills.js";


//import FormControl from "@material-ui/core/FormControl";
//import InputLabel from "@material-ui/core/InputLabel";
//import MenuItem from "@material-ui/core/MenuItem";
//import Select from "@material-ui/core/Select";
// import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
// import CardHeader from "components/Card/CardHeader.js";
// import Badge from "components/Badge/Badge.js";
import Muted from "components/Typography/Muted.js";
//import Parallax from "components/Parallax/Parallax.js";
import Clearfix from "components/Clearfix/Clearfix.js";
import Button from "components/CustomButtons/Button.js";

//import useLocalStorage from "hooks/useLocaleStorage.js";

import SectionSliderUnit from "views/PresentationPage/Sections/SectionSliderUnit.js";

import SectionProductPage from "views/AddProductPage/Sections/SectionProductPage.js";

import SectionLocalisation from "views/LocalisationPage/SectionLocalisation.js"

import Compteur from "views/CompteurPage/CompteurPage.js"

import SectionColor from "views/AddProductPage/Sections/SectionColor.js";
import SectionMateriau from "views/AddProductPage/Sections/SectionMateriau.js";
import SectionFinition from "views/AddProductPage/Sections/SectionFinition.js";
import SectionFournisseur from "views/AddProductPage/Sections/SectionFournisseur.js";
import SectionMarque from "views/AddProductPage/Sections/SectionMarque.js";
import SectionFabricant from "views/AddProductPage/Sections/SectionFabricant.js";



//import FormControlLabel from "@material-ui/core/FormControlLabel";

//import Radio from "@material-ui/core/Radio";

import profilePageStyle from "assets/jss/material-kit-pro-react/views/profilePageStyle.js";

//import InputAdornment from "@material-ui/core/InputAdornment";
//import Icon from "@material-ui/core/Icon";
// @material-ui/icons
//import Email from "@material-ui/icons/Email";

//import Face from "@material-ui/icons/Face";

//import CustomInput from "components/CustomInput/CustomInput.js";
import { TextField } from '@material-ui/core/';
import { TextareaAutosize } from '@material-ui/core/';

//import { description } from "assets/jss/material-kit-pro-react";

const useStyles = makeStyles(profilePageStyle);

export default function ProfilePage({ ...rest }) {
  React.useEffect(() => {
    // window.scrollTo(0, 0);
    // document.body.scrollTop = 0;
  });

  const [addcatalogueInfo, setaddcatalogueInfo] = useState({
    designationLabel: '',
    // descriptionArea: '',
  });
 
  const onChangeValue = (e) => {
    setaddcatalogueInfo({
      ...addcatalogueInfo,
      [e.target.name]: e.target.value
    });
  }
  
  const classes = useStyles();

  const [id_std_create, setIdStdCreate] = React.useState([]);

  const [messageAdd, resultAddProduct] = useState([]);
  const [messageUpdate, resultUpdateProduct] = useState([]);

  const addToCatalogue = async (event) => {
    try {
      event.preventDefault();
      event.persist();
      if (sessionStorage.getItem("id_std")) {
        sessionStorage.removeItem("id_std");
      }
      Axios.defaults.withCredentials = true;

      Axios.post(`${ApiConfig.baseUrl}/Controls/AddToCatalogue.php`, {

        productDesignation: addcatalogueInfo.designationLabel,
        productType: sessionStorage.getItem("setSelectedEnabled"),
        productDescription: addcatalogueInfo.descriptionArea,

        productHeight: sessionStorage.getItem("setHeightUnit"),
        productWidth: sessionStorage.getItem("setWidthUnit"),
        productDeph: sessionStorage.getItem("setDepthUnit"),
        productVolume: sessionStorage.getItem("setVolumeUnit"),
        id_pro:sessionStorage.getItem("getIdOwnerSelect"),

      })
        .then(res => {
          //console.log(res.data.productdata)
          if (res.data.productdata['success'] === true) {
            resultAddProduct('Votre produit est ajouté');
            sessionStorage.setItem("id_std", res.data.productdata['id_std']);
            setIdStdCreate(res.data.productdata['id_std']);
          }
          else {
            resultAddProduct('La désignation du produit est manquante');
          }
        })
    } catch (error) { throw error; }
  };

  const addToProduct = async (event) => {
    try {
      event.preventDefault();
      event.persist();

      Axios.post(`${ApiConfig.baseUrl}/Controls/AddToProduct.php`, {
        idColor: sessionStorage.getItem("getColorSelect"),
        idMateriau: sessionStorage.getItem("getMateriauSelect"),
        idFinition: sessionStorage.getItem("getFinitionSelect"),
        idFournisseur: sessionStorage.getItem("getFournisseurSelect"),
        idMarque: sessionStorage.getItem("getMarqueSelect"),
        idFabricant: sessionStorage.getItem("getFabricantSelect"),        
        productId: sessionStorage.getItem("id_std"),
      })
        .then(res => {
          //console.log(res)
        if(res.data.productupdatedata.success===true){
          resultUpdateProduct('Votre produit est mis à jour');
        }
        else if(res.data.productupdatedata.success===false){
        resultUpdateProduct('Echec de la Maj des caractéristiques');
        }
        })
    } catch (error) { throw error; }
  };


  return (
    <div>
      <Header
        color="black"
        brand="Top25"
        links={<HeaderLinks dropdownHoverColor="whiteColor" />}
        fixed
        changeColorOnScroll={{
          height: 200,
          color: "white"
        }}
        {...rest}
      />

      <br /><br />      <br /><br />

      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={6}>
              <div className={classes.profile}>
                <br /><br /><br /><br /><br />
                <div className={classes.name}>
                  <h3 className={classes.title}>INTERFACE PRINCIPALE</h3>
                  <h6>INVENTAIRE</h6>
                </div>
              </div>
            </GridItem>
          </GridContainer>
          <div className={classNames(classes.description, classes.textCenter, classes.textBlue)}>
            <p>
              Ajoutez des produits à votre inventaire, consultez le catalogue d'inventaire.
            </p>
          </div>
          <div className={classes.profileTabs}>
            <NavPills
              alignCenter
              color="info"
              tabs={[
                {
                  tabButton: "Ajouter à l'inventaire",
                  tabIcon: ArtTrack,
                  tabContent: (
                    <div>
                      <form className={classes.form} onSubmit={addToCatalogue}>
                        <GridContainer justify="center">
                          <GridItem
                            xs={10}
                            sm={10}
                            md={10}
                            className={classes.gridItem}
                          >
                            <Muted>
                              <h4 className={classes.title}>Ajouter</h4>
                            </Muted>
                            <br />
                            <TextField className={classes.input}
                              type="text"
                              fullWidth
                              id="designationLabel"
                              color="primary"
                              name="designationLabel"
                              variant="standard"
                              onChange={onChangeValue}
                              label="Désignation"
                              placeholder="Nom du produit..."
                              autoComplete="off"
                              required
                            />
                          </GridItem>
                        </GridContainer>
                        <br />   <br />


                        <GridContainer>
                          <GridItem xs={10}
                            sm={10}
                            md={10}
                            className={classes.gridItem}>

                            <SectionProductPage />

                            <Muted>
                              <h4 className={classes.title}>Description</h4>
                            </Muted>
                            <TextareaAutosize className={classes.textAreaWithStyle}
                              type="text"
                              fullWidth
                              id="descriptionArea"
                              color="primary"
                              name="descriptionArea"
                              variant="standard"
                              onChange={onChangeValue}
                              label="Description"
                              placeholder="Décrivez le produit..."
                              autoComplete="off"
                              required
                            />
                          </GridItem>
                        </GridContainer>
                        {/* <br /> <br /> */}
                        <GridContainer justify="center">
                          <GridItem
                            xs={12}
                            sm={12}
                            md={12}
                          >
                            <br /><br />
                            <SectionSliderUnit />
                          </GridItem>
                        </GridContainer>
                        <GridContainer justify="center">
                          <GridItem
                            xs={12}
                            sm={12}
                            md={6}
                            className={classes.gridItem}
                          >
                            <div className={classes.textCenter}>
                              <Button color="info" round onClick={addToCatalogue}>Créer le produit
                              </Button>
                              <br />
                              <h6>{messageAdd}</h6>
                            </div>
                          </GridItem>
                        </GridContainer>
                      </form>
                      <GridContainer justify="center">
                        <GridItem
                          xs={12}
                          sm={12}
                          md={10}
                          className={classes.gridItem}
                        >
                          <Muted>
                            <h4 className={classes.title}>Photos</h4>
                          </Muted>
                          <GridContainer justify="center">
                            <GridItem
                              xs={12}
                              sm={12}
                              md={3}
                              className={classes.gridItem}
                            >
                              <ImageUpload id='1'
                                addButtonProps={{ round: true }}
                                changeButtonProps={{ round: true }}
                                removeButtonProps={{ round: true, color: "danger" }}
                                uploadButtonProps={{ round: true, color: "info" }}
                              />
                            </GridItem>
                            <GridItem
                              xs={12}
                              sm={12}
                              md={3}
                              className={classes.gridItem}
                            >
                              <ImageUpload id='2'
                                addButtonProps={{ round: true }}
                                changeButtonProps={{ round: true }}
                                removeButtonProps={{ round: true, color: "danger" }}
                                uploadButtonProps={{ round: true, color: "info" }}
                              /></GridItem>
                            <GridItem
                              xs={12}
                              sm={12}
                              md={3}
                              className={classes.gridItem}
                            >
                              <ImageUpload id='3'
                                addButtonProps={{ round: true }}
                                changeButtonProps={{ round: true }}
                                removeButtonProps={{ round: true, color: "danger" }}
                                uploadButtonProps={{ round: true, color: "info" }}
                              /></GridItem>
                          </GridContainer>


                          {/*  <GridContainer justify="center">
                            <GridItem
                              xs={12}
                              sm={12}
                              md={3}
                              className={classes.gridItem}
                            >
                              <ImageUpload id='4'
                                addButtonProps={{ round: true }}
                                changeButtonProps={{ round: true }}
                                removeButtonProps={{ round: true, color: "danger" }}
                                uploadButtonProps={{ round: true, color: "info" }}
                              /></GridItem>
                              <GridItem
                              xs={12}
                              sm={12}
                              md={3}
                              className={classes.gridItem}
                            >
                              <ImageUpload id='5'
                                addButtonProps={{ round: true }}
                                changeButtonProps={{ round: true }}
                                removeButtonProps={{ round: true, color: "danger" }}
                                uploadButtonProps={{ round: true, color: "info" }}
                              /></GridItem>
                            <GridItem
                              xs={12}
                              sm={12}
                              md={3}
                              className={classes.gridItem}
                            >
                              <ImageUpload id='6'
                                addButtonProps={{ round: true }}
                                changeButtonProps={{ round: true }}
                                removeButtonProps={{ round: true, color: "danger" }}
                                uploadButtonProps={{ round: true, color: "info" }}
                              /></GridItem> 
                               <GridItem
                              xs={12}
                              sm={12}
                              md={3}
                              className={classes.gridItem}
                            >
                              <ImageUpload id='7'
                                addButtonProps={{ round: true }}
                                changeButtonProps={{ round: true }}
                                removeButtonProps={{ round: true, color: "danger" }}
                                uploadButtonProps={{ round: true, color: "info" }}
                              /></GridItem>
                            <GridItem
                              xs={12}
                              sm={12}
                              md={3}
                              className={classes.gridItem}
                            >
                              <ImageUpload id='8'
                                addButtonProps={{ round: true }}
                                changeButtonProps={{ round: true }}
                                removeButtonProps={{ round: true, color: "danger" }}
                                uploadButtonProps={{ round: true, color: "info" }}
                              /></GridItem> 
                          </GridContainer>*/}
                        </GridItem>
                      </GridContainer>
                      <GridContainer>
                        <GridItem
                          xs={12}
                          sm={12}
                          md={12}
                          className={classes.gridItem}
                        >
                          <Muted>
                            <h4 className={classes.title}>Localisation</h4>
                          </Muted>
                        </GridItem>
                      </GridContainer>
                      <SectionLocalisation />
                      <GridContainer>
                        <GridItem xs={12}
                          sm={12}
                          md={12}>
                          <Card product plain>
                            <CardBody className={classes.textCenter} plain>
                              <div>
                                <Compteur
                                  id_std={id_std_create}
                                />
                              </div>
                            </CardBody>
                          </Card>
                        </GridItem>
                      </GridContainer>
                      
                      <form className={classes.form} onSubmit={addToProduct}>
                        <br /><br />
                        <GridContainer>
                          <GridItem
                            xs={12}
                            sm={12}
                            md={12}
                            className={classes.gridItem}
                          >
                            <Muted>
                              <h4 className={classes.title}>Complément d'information</h4>
                            </Muted>
                           <br />
                            <GridContainer className={classes.pickSize}>
                          <GridItem md={6} sm={6}>
                            <SectionColor />
                              </GridItem> 
                              <GridItem md={6} sm={6}>
                            <SectionMateriau />
                              </GridItem>
                               <GridItem md={6} sm={6}>
                            <SectionFinition />
                              </GridItem>
                               <GridItem md={6} sm={6}>
                            <SectionFournisseur />
                              </GridItem>
                               <GridItem md={6} sm={6}>
                            <SectionMarque />
                              </GridItem>
                               <GridItem md={6} sm={6}>
                            <SectionFabricant />
                              </GridItem>            
                            </GridContainer>
                          </GridItem>
                        </GridContainer>
                        <GridContainer justify="center">
                          <GridItem
                            xs={12}
                            sm={12}
                            md={6}
                            className={classes.gridItem}
                          >
                            <div className={classes.textCenter}>
                              <br />
                              <Button color="info" round onClick={addToProduct}>ENREGISTRER
                              </Button>
                              <br />
                              <h6>{messageUpdate}</h6>
                            </div>
                          </GridItem>
                        </GridContainer>
                      </form>
                      <br /><br />
                    </div>
                  )
                },
                {
                  tabButton: "Consulter l'inventaire",
                  tabIcon: Camera,
                  tabContent: (
                    <GridContainer justify="center">
                      <GridItem xs={12} sm={12} md={6}>
                        <div className={classes.textCenter}>
                          <Link to="/catalogue-page">
                            <Button round color="info">
                              inventaire
                            </Button>
                          </Link>

                        </div>
                      </GridItem>
                    </GridContainer>
                  )
                }                
              ]}
            />
          </div>
          <Clearfix />
        </div>
      </div>
      <br/><br/>
    </div>
  );
}
