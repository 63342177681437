import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// plugin that creates slider
import Slider from "nouislider";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import Tooltip from "@material-ui/core/Tooltip";
import FormControlLabel from "@material-ui/core/FormControlLabel";
// @material-ui icons
import Favorite from "@material-ui/icons/Favorite";
import FavoriteBorder from "@material-ui/icons/FavoriteBorder";
import Cached from "@material-ui/icons/Cached";
import Subject from "@material-ui/icons/Subject";
import Check from "@material-ui/icons/Check";
// core components
import Accordion from "components/Accordion/Accordion.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Button from "components/CustomButtons/Button.js";
import Clearfix from "components/Clearfix/Clearfix.js";
import color1 from "assets/img/worlds-best-restaurant-at-top25-restaurants-maybourne-riviera-roquebrune.jpg";
import color2 from "assets/img/worlds-best-restaurant-at-top25-restaurants-signature-bangkok.jpg";
import color3 from "assets/img/worlds-best-restaurant-at-top25-restaurants-sirocco-bangkok.jpg";

import suit1 from "assets/img/examples/suit-1.jpg";
import suit2 from "assets/img/examples/suit-2.jpg";
import suit3 from "assets/img/examples/suit-3.jpg";
import suit4 from "assets/img/examples/suit-4.jpg";
import suit5 from "assets/img/examples/suit-5.jpg";
import suit6 from "assets/img/examples/suit-6.jpg";



import dg3 from "assets/img/dg3.jpg";
import dg1 from "assets/img/dg1.jpg";
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';


import styles from "assets/jss/material-kit-pro-react/views/ecommerceSections/productsStyle.js";

const useStyles = makeStyles(styles);

export default function SectionProducts() {


  const smoothScroll = target => {
    var targetScroll = document.getElementById(target);
    targetScroll.scrollIntoView({ behavior: 'smooth' });
  };

  const classes = useStyles();
  return (
    <div className={classes.section_}>
      <div className={classes.cardBodyRefine}>

        <GridContainer>
          <GridItem md={6} sm={6}>
            <Card background style={{ backgroundImage: `url(${color1})` }}>
              <CardBody background>
                <h6
                  className={classNames(classes.cardCategory, classes.textInfo)}
                >
                  Top25restaurants
                </h6>
                <a href="#">
                  <h3 className={classes.cardTitle}>
                  World's Best Restaurants
                  </h3>
                </a>
                <p className={classes.description}>
                 
                </p>
                {/* <Button href="#" round color="white">
                  <Subject /> Read
                </Button> */}
              </CardBody>
            </Card>
          </GridItem>
          <GridItem md={6} sm={6}>
            <Card background style={{ backgroundImage: `url(${color3})` }}>
              <CardBody background>
                <h6
                  className={classNames(classes.cardCategory, classes.textInfo)}
                >
                  Top25restaurants

                </h6>
                <a href="#">
                  <h3 className={classes.cardTitle}>
                  Top by region
                  </h3>
                </a>
                <p className={classes.description}>

                </p>


                <Button round className={classes.buttonEflex}

                  onClick={() => smoothScroll('bottomSection')}
                >
                  <ArrowDownwardIcon />
                </Button>

                
              </CardBody>
            </Card>
          </GridItem>
         {/*  <GridItem md={4} sm={4}>
            <Card background style={{ backgroundImage: `url(${color2})` }}>
              <CardBody background>
                <h6
                  className={classNames(classes.cardCategory, classes.textInfo)}
                >
                 Top25restaurants
                </h6>
                <a href="#">
                  <h3 className={classes.cardTitle}>
                    Discover
                  </h3>
                </a>
                <p className={classes.description}>

                </p>
                <Button round className={classes.buttonEflex}

                  onClick={() => smoothScroll('bottomSection')}
                >
                  <ArrowForwardIcon />
                </Button>
               
              </CardBody>
            </Card>
          </GridItem> */}
        </GridContainer>
      </div>
      <div id="bottomSection"></div>
    </div>
  );
}
