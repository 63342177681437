/*eslint-disable*/
import React from "react";
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import SectionCatalogue from "views/CataloguePage/Sections/SectionCatalogue.js";
import { makeStyles } from "@material-ui/core/styles";
//import styles from "assets/jss/material-kit-pro-react/views/catalogueStyle.js";
import SectionSearchGroup from "views/PresentationPage/Sections/SectionSearchGroup.js";
import styles from "./catalogueStyle.js";

const useStyles = makeStyles(styles);

export default function EcommercePage() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
 
  });
  const classes = useStyles();
  return (
    <div>
      <Header
        brand="Top25"
        links={<HeaderLinks dropdownHoverColor="white" />}
        fixed
        color="white"
        changeColorOnScroll={{
          height: 300,
          color: "white"
        }}
      />
      <div className={classes.main}> 
{/*       <SectionSearchGroup />
 */}   
 <SectionCatalogue />
  
     </div>     
    </div>
  );
}
