import React, { useEffect, useState, useRef } from "react";

import Axios from 'axios';
import ApiConfig from "_config/ApiConfig";
//import { Link } from "react-router-dom";

import classNames from "classnames";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Button from "components/CustomButtons/Button.js";
//import Badge from "components/Badge/Badge.js";
import Tooltip from "@material-ui/core/Tooltip";
// @material-ui icons
import Favorite from "@material-ui/icons/Favorite";
//import FavoriteBorder from "@material-ui/icons/FavoriteBorder";
//import Remove from "@material-ui/icons/Remove";
//import Add from "@material-ui/icons/Add";
import RotateRightIcon from '@material-ui/icons/RotateRight';
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui icons
import ArrowForwardIcon from '@material-ui/icons/ArrowUpwardOutlined';
//import styles from "assets/jss/material-kit-pro-react/views/ecommerceSections/catalogueOffersStyle.js";
import styles from "./sectionCatalogueStyle.js";
// react component used to create nice image meadia player
import ImageGallery from "react-image-gallery";

import Accordion from "components/Accordion/Accordion.js";
//import Default from "components/ToggleVisibility/Default.js";

//import ToggleVisibility from "components/ToggleVisibility/ToggleVisibility.js";
//import ToggleVisibilityMove from "components/ToggleVisibility/ToggleVisibilityMove.js";
//import ToggleVisibilityUpdate from "components/ToggleVisibility/ToggleVisibilityUpdate.js";


//import SectionPays from "views/AddProductPage/Sections/SectionFamille.js";
//import SectionColor from "views/AddProductPage/Sections/SectionColor.js";
//import SectionMateriau from "views/AddProductPage/Sections/SectionMateriau.js";
//import SectionFinition from "views/AddProductPage/Sections/SectionFinition.js";
//import SectionFournisseur from "views/AddProductPage/Sections/SectionFournisseur.js";
//import SectionMarque from "views/AddProductPage/Sections/SectionMarque.js";
//import SectionFabricant from "views/AddProductPage/Sections/SectionFabricant.js";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import RotateLeftIcon from '@material-ui/icons/RotateLeft';

//import SectionLocalisation from "views/LocalisationPage/SectionLocalisation.js";

//import SectionLatestOffers from "views/EcommercePage/Sections/SectionLatestOffers.js";
import SectionProducts from "views/EcommercePage/Sections/SectionProducts.js";
import DefaultInput from "components/ToggleVisibility/DefaultInput.js";
import ToggleVisibilityAddInput from "components/ToggleVisibility/ToggleVisibilityAddInput.js";

//import Compteur from "views/CompteurPage/CompteurPage.js";

//import ListeInventory from "views/LocalisationPage/ListeInventoryPage.js";

import top25selection from "assets/img/top25restaurants-overlay1.png";

import Iframe from 'react-iframe'

const useStyles = makeStyles(styles);


export default function SectionCatalogue() {

  const classes = useStyles();
  //const [messageCountArticle, resultCountArticle] = useState([]);

  const [messageEmptyInventory, resultEmptyInventory] = useState([]);

  const position = useRef(null);


  const [isShowInformation, setShowInformation] = useState(2);
  const [iscatalogue, setcatalogue] = useState([]);

  const [isitem, setitem] = useState([]);

  const [images, showPicture] = useState([]);

  const resetIndexData = () => {
    sessionStorage.setItem("ctr_index", 0);//seteur a 0 dans image gallery
  }

  const [isPayProduct, setPayProduct] = useState([]);
  const [paySelect, setPayselect] = useState();
  /*CATALOGUE*/
  const getCatalogueData = async (id_pay) => {
    try {
      Axios.defaults.withCredentials = true;
      Axios.post(`${ApiConfig.baseUrl}/Controls/GetCatalogue.php`, {
        id_pay: id_pay,

      })
        .then(res => {
          if (res.data.success === true) {
            setcatalogue(res.data.cataloguedata);
            setShowInformation(2);
            //resultCountArticle(res.data.recordCount + " Produit(s)");
          }
          else {
            // console.log(res.data.query);
            resultEmptyInventory('Not Found');

          }
          return;
        })
    } catch (error) { throw error; }
  };

  /*CHARGE les caracteristiques du produit*/
  const getItemData = async (id) => {
    try {
      Axios.defaults.withCredentials = true;
      await Axios.post(`${ApiConfig.baseUrl}/Controls/GetItem.php`, {
        id_est: id,
      })
        .then(res => {
          if (res.data.success === true) {
            setShowInformation(1);
            setitem(res.data.itemdata);//data avec l'image en position 1
            getPicturesData(id);//photo
            smoothScrollTarget("showItemId");
          }
          else {
            //setShowInformation(2);
          }
          return;
        })
    } catch (error) { throw error; }
  };

  //CONSTRUIT LE TABLEAU IMAGE GALLERY
  const getPicturesData = async (id) => {
    try {
      Axios.defaults.withCredentials = true;
      Axios.post(`${ApiConfig.baseUrl}/Controls/GetPictures.php`, {
        id_est: id,
      })
        .then(res => {
          if (sessionStorage.getItem("id_est")) {
            sessionStorage.removeItem("id_est");
          }
          sessionStorage.setItem("id_est", res.data.id_est);
          showPicture(res.data.picturedata);
          return;
        })
    } catch (error) { throw error; }
  };


  const getItemRotate = async (id) => {
    //console.log(position.current.state.currentIndex)
    try {
      Axios.defaults.withCredentials = true;
      Axios.post(`${ApiConfig.baseUrl}/Controls/RotatePicture.php`, {
        id_est: id, position: position.current.state.currentIndex,
      })
        .then(res => {

          getCatalogueData();//recharge le catalogue et prends la mise à jour de l'image
          getItemData(id);
          return;
        })
    } catch (error) { throw error; }
  };

  const smoothScroll = target => {
    var targetScroll = document.getElementById(target);
    targetScroll.scrollIntoView({ behavior: 'smooth' });
  };


  const easeInOutQuad = (t, b, c, d) => {
    t /= d / 2;
    if (t < 1) return (c / 2) * t * t + b;
    t--;
    return (-c / 2) * (t * (t - 2) - 1) + b;
  };

  const smoothScrollTarget = (target) => {
    var targetScroll = document.getElementById(target);
    scrollGo(document.documentElement, targetScroll.offsetTop, 1250);
  };

  const smoothScrollClickTarget = (e, target) => {
    e.preventDefault();
    var targetScroll = document.getElementById(target);
    scrollGo(document.documentElement, targetScroll.offsetTop, 1250);
  };

  const scrollGo = (element, to, duration) => {
    var start = element.scrollTop,
      change = to - start,
      currentTime = 0,
      increment = 20;

    var animateScroll = function () {
      currentTime += increment;
      var val = easeInOutQuad(currentTime, start, change, duration);
      element.scrollTop = val;
      if (currentTime < duration) {
        setTimeout(animateScroll, increment);
      }
    };
    animateScroll();
  };

  const getPayProduct = async (ids) => {
    try {
      Axios.defaults.withCredentials = true;
      Axios.get(`${ApiConfig.baseUrl}/Controls/GetPayProduct.php`)
        .then(res => {
          setPayProduct(res.data.payproductdata);
          return;
        })
    } catch (error) { throw error; }
  };

  useEffect(() => {
    // window.scrollTo(0, 0);
    // document.body.scrollTop = 0;
    getCatalogueData();//charge le catalogue par default
    //getItemData(100000);//renvoi false
    getPayProduct();//charge le filtre PAys

  }, []);

  return (
    <div id="showCatalogueId" className={classes.section}>
      {/* <SectionLatestOffers />
 */}
      <SectionProducts />

     <div className={classes.container}>

        
        <br />
        <br />
        {/* <SectionLocalisation />
        <br />
        <br /> */}
<br />
        <br />
        {/*  <ListeInventory getCatalogueData={getCatalogueData} /> */}
        <GridContainer justify="center">
          <GridItem
            xs={12}
            sm={12}
            md={12}
            className={classes.gridItem}
          >
            <div className={classes.textCenter}>
              <h2 className={classNames(classes.shadowForText, classes.textBlack, classes.textBolder)}>
                World's Best Restaurants
              </h2> </div>
          </GridItem>
        </GridContainer>
        <h4 className={classNames(classes.textWithSmallShade, classes.textSemiBolder)}>
          A collection of exceptional restaurants featuring the top 25 restaurants in the world and in each region. <br></br>
          Browse the list of prestigious restaurant nominees as our experts are working on the world's rankings. <br></br>TOP25 Restaurants, a restaurant guide and annual awards with ratings and rankings based on the patented, artificial intelligence driven "Restaurant Rating Index" and re-assessed by some of the world's top culinary experts.
        </h4>
        <form className={classes.form} onSubmit={''}>
          <GridContainer className={classes.pickSize}>
            <GridItem md={6} sm={6}>


              <div className={classes.containerForm}>
                <label>FILTER</label><ToggleVisibilityAddInput>
                  <DefaultInput
                    valueString={'Region'}
                  />
                </ToggleVisibilityAddInput>
                <FormControl
                  fullWidth
                  className={classes.selectFormControl}
                >
                  <Select
                    MenuProps={{
                      className: classes.selectMenu
                    }}
                    classes={{
                      select: classes.select
                    }}
                    value={paySelect}
                    onChange={(event) => { getCatalogueData(event.target.value) }}
                    /*  onChange={event => setPayselect(event.target.value);getItemData(10)}
                     */
                    inputProps={{
                      name: "paySelect",
                      id: "paySelect"
                    }}
                  >
                    {isPayProduct.map((item) => (
                      <MenuItem key={item.id_pay}
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected
                        }}
                        value={item.id_pay}
                      >
                        {item.name_pay}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>



            </GridItem>
            <GridItem md={3} sm={3}>
              <div className={classNames(classes.elementRight, classes.pullRight)}>

                <Button round className={classes.buttonBleu}

                  onClick={() => getCatalogueData()}

                ><RotateLeftIcon />
                </Button>
              </div>
              {/* <SectionFournisseur /> */}
            </GridItem>
            {/*  <GridItem md={2} sm={2}>
            <SectionMateriau />
          </GridItem>
          <GridItem md={2} sm={2}>
            <SectionColor />
          </GridItem>
          <GridItem md={2} sm={2}>
            <SectionFinition />
          </GridItem>
         
          <GridItem md={2} sm={2}>
            <SectionMarque />
          </GridItem>
          <GridItem md={2} sm={2}>
            <SectionFabricant />
          </GridItem>  */}
          </GridContainer>
        </form>
       {/*  <ToggleVisibility>
          <Default />
        </ToggleVisibility> */}
        <div className={classes.textCenter}>
          <br />
          {/*          <h6 className={classes.textWarning}>{messageEmptyInventory}</h6>
 
          <h3 className={classNames(classes.textWhite, classes.textBolder)}>{messageCountArticle}</h3>*/}
        </div>
        <GridContainer>
          <div id='topCatalogue' />
          {iscatalogue.map((item) => (
            <GridItem xs={12} sm={6} md={4} key={item.id_est}>
              <div id={"cat_" + item.id_est} />
              {/*  <br /> <br /><br /> */}
              <Card product plain className={classes.shadowForCard}>
                <CardHeader image plain>
                  <div className={classes.shadowForPicture}
                    style={{
                      backgroundImage: `url(${top25selection}),url(${ApiConfig.baseUrl}/${item.image})`,
                      backgroundSize: 'auto, cover',
                      backgroundRepeat: "no-repeat, no-repeat",
                      backgroundPosition: '3% 8%, center center',
                      opacity: "1"
                    }}
                  >
                    <a href="#showItemId" onClick={() => { getItemData(item.id_est); resetIndexData(); }}>
                      <p>&nbsp;</p>
                      <p>&nbsp;</p>
                      <p>&nbsp;</p>
                      <p>&nbsp;</p>
                      <p>&nbsp;</p>
                      <p>&nbsp;</p>
                      <p>&nbsp;</p>
                    </a>
                  </div>
                </CardHeader>
                <CardBody className={classes.textCenter} plain>

                  <p className={classes.textBolder}><span className={classNames(classes.textEflex, classes.textBolder, classes.textSize,)}>{item.pays}</span></p>
                  {/*                 <p className={classes.textBolder}>ID : {item.id_est}  <span className={classNames(classes.textEflex, classes.textBolder)}>{item.pays}</span></p>
 */}
                  <div className={classNames(classes.textLeft, classes.cardBodyRefine)}>
                    <h4 className={classNames(classes.textMLV, classes.textBolder)}>{item.libelle}</h4>

                    <p className={classes.textBolder}>Cuisine : {item.cuisine}</p>
                    <p className={classes.textBolder}>Chef : {item.chef}</p>
                    <p className={classes.textBolder}>Pricing  : {item.pricing}</p>
                    <p className={classes.textBolder}>Location: {item.location}</p>
                    <span className={classNames(classes.textEflex, classes.textBolder)}>
                      {item.description}
                    </span>
                  </div>
                  <div>
                    <div className={classes.mlAuto}>

                      {/*  <h4 className={classNames(classes.line, classes.textBolder)}>{item.selectFormatPriceBefore}</h4>
                      <h4 className={classNames(classes.textEflex, classes.textBolder)}>{item.selectFormatPrice}</h4> */}
                      <Tooltip
                        id="tooltip-top"
                        title="Saved to Wishlist"
                        placement="left"
                        classes={{ tooltip: classes.tooltip }}
                      >
                        <Button
                          justIcon
                          simple
                          color="primary"
                          className={classes.pullRight}
                        >
                          <Favorite />
                        </Button>
                      </Tooltip>

                    </div>
                    {/* <Compteur
                      id_est={item.id_est}
                    /> */}

                  </div>
                </CardBody>
                <CardFooter plain>
                  <p className={classes.textJustify}>
                    {/*  {item.description} */}
                  </p>
                </CardFooter>
              </Card>
              {/* </div> */}
            </GridItem>
          ))}


        </GridContainer>
        {/* <br /><br /><br /><br /><br /><br /><br /><br /><br /> */}
        <div id="showItemId"></div>
        {isShowInformation === 1 ? (
        <div className={classes.productPage}>
         

          {isitem.map((itemP) => (
            <div className={classes.ItemPage}>
              <div className={classNames(classes.container, classes.shadowForBox_)}>
                

                <GridContainer>
                  <GridItem md={6} sm={6}>
                    <div className={classes.elementPadding} />
                    <ImageGallery 
                      showFullscreenButton={false}
                      showPlayButton={false}
                      startIndex={parseInt(sessionStorage.getItem("ctr_index"))}
                      ref={position}
                      items={images}
                      showThumbnails={true}
                      renderLeftNav={(onClick, disabled) => {
                        return (
                          <button
                            className='image-gallery-left-nav'
                            disabled={disabled}
                            onClick={onClick}
                          />
                        );
                      }}
                      renderRightNav={(onClick, disabled) => {
                        return (
                          <button
                            className='image-gallery-right-nav'
                            disabled={disabled}
                            onClick={onClick}

                          />
                        );
                      }}
                      renderCustomControls={(onClick) => {
                        if (sessionStorage.getItem("statut_clt") === 'modeAdmin') {
                          return (
                            <Button
                              color="transparent"
                              size="sm"
                              round
                              onClick={() => getItemRotate(sessionStorage.getItem("id_est"))} >
                              <RotateRightIcon /></Button>
                          );
                        }
                      }}
                    />

                  </GridItem>
                  <GridItem md={6} sm={6}>
                    <h2 className={classNames(classes.textMLV, classes.textBolder)}>{itemP.libelle}</h2>

                    {/*                     <h3 className={classes.mainPrice}>{itemP.pays}</h3>
 */}
                    <h4 className={classNames(classes.textEflex, classes.textBolder)}>Location: {itemP.location}</h4>

                    <Accordion
                      active={0}
                      activeColor="info"
                      collapses={[
                        {
                          title: "Description",
                          content: (
                            <p className={classNames(classes.breakLine,classes.textWithSmallShade, classes.textSemiBolderLight, classes.textMLV)}> {itemP.description}
                            </p>
                          )
                        }
                      ]}
                    />
                    <Accordion
                      active={0}
                      activeColor="info"
                      collapses={[
                        {
                          title: "Informations",
                          content: (
                            <ul>
                              <li className={classNames(classes.textWithSmallShade, classes.textSemiBolderLight, classes.textEflex)}>Cuisine: {itemP.cuisine}</li>
                              <li className={classNames(classes.textWithSmallShade, classes.textSemiBolderLight, classes.textEflex)}>Chef: {itemP.chef}</li>
                              <li className={classNames(classes.textWithSmallShade, classes.textSemiBolderLight, classes.textEflex)}>Pricing: {itemP.pricing}</li>
                              <li className={classNames(classes.textWithSmallShade, classes.textSemiBolderLight, classes.textEflex)}>Seating: {itemP.seating}</li>
                              <li className={classNames(classes.textWithSmallShade, classes.textSemiBolderLight, classes.textEflex)}>Opening Hours: {itemP.opening_hours}</li>
                            </ul>
                          )
                        }
                      ]}
                    />
                    <Accordion
                      active={0}
                      activeColor="info"
                      collapses={[
                        {
                          title: "Contact Information",
                          content: (
                            <ul>
                              <li className={classNames(classes.textWithSmallShade, classes.textSemiBolderLight, classes.textEflex)}>Phone:  : {itemP.phone}</li>
                              <li className={classNames(classes.textWithSmallShade, classes.textSemiBolderLight, classes.textEflex)}>Email:  : Send a message</li>
                              <li className={classNames(classes.textWithSmallShade, classes.textSemiBolderLight, classes.textEflex)}>Web:  : {itemP.web}</li>
                            </ul>
                          )
                        }
                      ]}
                    />
                    <Accordion
                      active={0}
                      activeColor="info"
                      collapses={[
                        {
                          title: "Address:",
                          content: (
                            <ul>
                              <li className={classNames(classes.breakLine, classes.textWithSmallShade, classes.textSemiBolderLight, classes.textMLV)}>{itemP.adress}</li>
                            </ul>
                          )
                        }
                      ]}
                    />
                  </GridItem>

                  <GridItem md={12} sm={12}>
                    <br /><br />

                    <Iframe url={itemP.maps}
                      width="100%"
                      height="320px"
                      id=""
                      className={classes.contenerMap}
                      display="block"
                      position="relative" />
                    <br /><br />
                    <GridContainer className={classes.pullight}>
                      <GridItem>
                        {/* <ToggleVisibilityUpdate
                          product={itemP.id_est}
                        >

                        </ToggleVisibilityUpdate> */}
                        {/* <ToggleVisibilityMove></ToggleVisibilityMove> */}


                        <Button round className={classes.buttonBleu}
                          endIcon={<ArrowForwardIcon />}
                          onClick={() => smoothScroll('cat_' + itemP.id_est)}
                        //onClick={e => smoothScrollClickTarget(e, "showCatalogueId")}
                        >
                        </Button>
                      </GridItem>
                    </GridContainer>
                    <br /><br />
                    <br />
                  </GridItem>
                </GridContainer>
               
              </div>
            
            </div>
          )
          )}
          
        </div>) : ( <div></div>)}
        <br /><br />
      
        <br /><br />
        <GridContainer className={classNames(classes.elementRight, classes.pullRight)}>
          <Button round className={classes.buttonEflex}
            endIcon={<ArrowForwardIcon />}
            onClick={() => smoothScroll('topCatalogue')}
          //onClick={e => smoothScrollClickTarget(e, "showCatalogueId")}
          >
          </Button>

        </GridContainer>
        <br /><br />
      </div>
    </div>
  );
}
